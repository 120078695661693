<template>
	<div>
		<common-table
				:itemScope="['ostatus']"
				:headers="headers"
				:listData="listData"
				@changePage="changePage"
				:loading="loading"
				@search="search"
				:btnAction="true"
				ref="ct"
				pageTitle="线下订单"
				:customHtml="true"
				:btnActionEvent="btnActionEvent"
				@otherTableOperate="otherTableOperate"
		>
			<template v-slot:ostatus="{ item }">
				<!-- 格式化线下状态 -->
				<formatter-field :status="item.ostatus" field="offlineOrderStatus"/>
			</template>
			<template v-slot:customHtml>
				<advanced-search
						:fixedConfig="leftConfig"
						@search="search"
						:extraParams="extraParams"
						@advancedSearch="advancedSearch"
						:hasExportFile="true"
						@exportFile="exportFile"
				/>
			</template>
		</common-table>
		<template v-if="dialog">
			<form-menu
					:center="true"
					:center_btn="false"
					:listDeploy="deploy"
					height="50%"
					footer_btn_local="right"
					rightbtn_text="提交"
					:ridDivision="true"
					:btn_disabled_status="btn_disabled_status"
					:dialog.sync="dialog"
					@validateSave="validateSave"
					title="详情"
			>
				<div style="position: absolute; bottom: 16px; left: 24px">
					<v-btn
							@click="toRefund"
							v-if="operatePermissions.includes('82413')"
							:loading="refund_btn_loading" :disabled="btn_disabled_status"
							color="redness" class="white--text" depressed>退款
					</v-btn>
				</div>
			</form-menu>
		</template>
	</div>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/orderManage/offlineOrders.js';
import formatterField from '../formatter.vue';
import offlineOrders from "./offlineOrder";
import advancedSearch from "@/components/advancedSearch/advancedSearch.vue";
import formMenu from "@/components/form-menu/form-menu.vue";

export default {
	// 线下订单
	name: "OfflineOrdersIndex",
	components: {
		formatterField,
		formMenu,
		advancedSearch
	},
	mixins: [commonCurd, offlineOrders],
	data() {
		return {
			api,
			bookingid: '',
			extraParams: '',
			dialog: false,
			items: [],
			btnActionEvent: [
				{
					event: 'getDetails',
					icon: 'chakan-fill',
					color: 'primary',
					tooltip: '详情'
				}
			],
			headers: [
				{text: '商户订单号', value: 'payid'},
				{text: '线下订单号', value: 'outid'},
				{text: '付款人', value: 'guestname'},
				{text: '联系电话', value: 'tel'},
				{text: '支付状态', value: 'ostatus'},
				{text: '交易金额', value: 'amount'},
				{text: '客人备注', value: 'memo', width: '156px'},
				{text: '支付方式', value: 'payment'},
				{text: '创建时间', value: 'createdate'},
				{text: '订单状态', value: 'mainstatus'},
				{text: '操作', value: 'actions', width: '60px'}
			],
			leftConfig: [
				{type: 'input', label: '输入付款人', property: 'name'},
				{type: 'input', label: '输入联系电话', property: 'tel'},
				{type: 'input', label: '输入线下订单号', property: 'outid'},
				{type: 'input', label: '输入支付金额', property: 'amount'},
				{type: 'select', label: '选择订单状态', property: 'passRsStatus'},
				{type: 'select', label: '选择支付方式', property: 'payment', items: []}
			],
			btn_disabled_status: false,
			operatePermissions: [],
			refund_btn_loading: false
		}
	},
	methods: {
		toRefund() {
			this.confirm.showConfirm('确定退款？').then(() => {
				this.refund_btn_loading = true
				this.axios.post(this.api.refund, {bookingid: this.bookingid}).then(() => {
					this.refund_btn_loading = false
					this.dialog = false
					this.list()
					this.snackbar.success("退款成功");
				}).catch(() => {
					this.refund_btn_loading = false
				})
			}).catch(() => {
			});
		},
		advancedSearch() {
			this.$nextTick(() => {
				this.$refs.ct.getTableHeight();
			});
		},
		otherTableOperate(e, item) {
			this.bookingid = item.bookingid
			if (e === this.btnActionEvent[0].event) {
				this.axios.post(api.load, {bookingid: this.bookingid}).then((res) => {
					if (res.code === this.staticVal.Code.Success) {
						this.deploy.row.forEach((row) => {
							row.default = res.data[row.property];
							if (row.property === 'refundtime') {
								this.btn_disabled_status = !!res.data[row.property]
							}
						});
					}
					this.dialog = true
				})
			}
		},
		validateSave(item, callback) {
			if (item.done) {
				this.axios.post(this.api.save, Object.assign(item.form, {bookingid: this.bookingid})).then(r => {
					if (r.code === this.staticVal.Code.Success) {
						this.snackbar.success("数据更新成功");
						callback();
						this.list();
					} else {
						this.$refs.ct.$refs.formRef.load = false;
					}
				});
			}
		}
	},
	mounted() {
		let str = sessionStorage.getItem('operatePermissionKey');
		if (str) {
			this.operatePermissions = JSON.parse(str);
		}
		this.axios.post(this.select_data, {keyname: 'payment,passRsStatus'}).then((res) => {
			if (res.code === this.staticVal.Code.Success) {
				this.leftConfig[5].items = res.data[0].values
				this.leftConfig[4].items = res.data[1].values
				this.deploy.row[8].connector.props.items = res.data[1].values
			}
		})
	},
	created() {
		// 外部跳转的额外参数
		this.extraParams = this.$route.query.value;
	}
}
</script>

<style scoped lang="scss">

</style>
