import wAutocomplete from '@/components/w-autocomplete/w-autocomplete.vue';

export default {
	data() {
		return {
			deploy: {
				row: [
					{
						cols: 4,
						subassembly: "VTextField",
						property: 'payid',
						connector: {
							props: {
								label: "商户订单号",
								readonly: true
							}
						}
					},
					{
						cols: 4,
						subassembly: "VTextField",
						property: 'outid',
						connector: {
							props: {
								label: "线下订单号",
								readonly: true
							}
						}
					},
					{
						cols: 4,
						subassembly: "VTextField",
						property: 'createdate',
						connector: {
							props: {
								label: "创建时间",
								readonly: true
							}
						}
					},
					{
						cols: 4,
						subassembly: "VTextField",
						property: 'guestname',
						connector: {
							props: {
								label: "付款人",
								readonly: true
							}
						}
					},
					{
						cols: 4,
						subassembly: "VTextField",
						property: 'tel',
						connector: {
							props: {
								label: "联系电话",
								readonly: true
							}
						}
					},
					{
						cols: 4,
						subassembly: "VTextField",
						property: 'amount',
						connector: {
							props: {
								label: "交易金额",
								readonly: true
							}
						}
					},
					{
						cols: 4,
						subassembly: "VTextField",
						property: 'payment',
						connector: {
							props: {
								label: "支付方式",
								readonly: true
							}
						}
					},
					{
						cols: 4,
						subassembly: "VTextField",
						property: 'ostatus',
						connector: {
							props: {
								label: "支付状态",
								readonly: true
							},
							style: {
								color: 'red'
							}
						}
					},
					{
						cols: 4,
						subassembly: wAutocomplete,
						property: 'mainstatus',
						connector: {
							props: {
								label: "订单状态"
							},
							class: 'edit-text'
						}
					},
					{
						cols: 4,
						subassembly: "VTextField",
						property: 'refundtime',
						connector: {
							props: {
								label: "退款时间",
								readonly: true
							}
						}
					},
					{
						cols: 8,
						subassembly: "VTextField",
						property: 'opmemo',
						connector: {
							props: {
								label: "订单备注"
							},
							class: 'edit-text'
						}
					},
					{
						cols: 12,
						subassembly: "VTextField",
						property: 'memo',
						connector: {
							props: {
								label: "客人备注",
								readonly: true
							}
						}
					}
				]
			}
		}
	}
}
